<template>
  <v-card rounded :elevation="3" class="mb-12 pb-12">
    <v-card-text class="pa-0 ma-0 mb-8">
      <v-tabs
        v-model="currentTab"
        class="c-tabs"
        height="55"
        color="blue"
        show-arrows
      >
        <v-tab :id="0">
          {{ $trans("integrations_for_employee_tab") }}
        </v-tab>
        <v-tab v-if="loggedUserIsAdmin" :id="1">
          {{ $trans("integrations_for_global_tab") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" class="pt-8">
        <v-tab-item :transition="false" :reverse-transition="false">
          <v-container>
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_employees") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  :disabled="isLoading"
                  outlined
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleGoogleCalendarClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <div>
                          <v-card-title>
                            {{ $trans("integrations_google_tools_title") }}
                          </v-card-title>
                          <v-card-text class="d-flex align-center">
                            <v-img
                              max-width="60"
                              class="mr-4"
                              :src="require('@/assets/google-calendar.png')"
                            />
                            <p>
                              <span class="font-weight-medium">
                                {{ $trans("google_calendar") }}
                              </span>
                              <br />
                              {{ $trans("google_calendar_description") }}
                            </p>
                          </v-card-text>
                          <v-card-text class="d-flex align-center">
                            <v-img
                              max-width="60"
                              class="mr-4"
                              :src="require('@/assets/google-meet.png')"
                            />
                            <p>
                              <span class="font-weight-medium">
                                {{ $trans("google_meet") }}
                              </span>
                              <br />
                              {{ $trans("google_meet_description") }}
                            </p>
                          </v-card-text>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isGoogleCalendarEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isGoogleCalendarEnabled">{{
                      $trans("edit")
                    }}</span>
                    <span v-else>{{
                      $trans("google_calendar_connect_button_title")
                    }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  :disabled="isLoading"
                  outlined
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleOutlookCalendarClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <div>
                          <v-card-title>
                            {{ $trans("integrations_microsoft_tools_title") }}
                          </v-card-title>
                          <v-card-text class="d-flex align-center">
                            <v-img
                              max-width="60"
                              class="mr-4"
                              :src="require('@/assets/outlook-logo.png')"
                            />
                            <p>
                              <span class="font-weight-medium">
                                {{ $trans("microsoft_outlook") }}
                              </span>
                              <br />
                              {{ $trans("outlook_calendar_description") }}
                            </p>
                          </v-card-text>
                          <v-card-text class="d-flex align-center">
                            <v-img
                              max-width="60"
                              class="mr-4"
                              :src="require('@/assets/teams-logo.png')"
                            />
                            <p>
                              <span class="font-weight-medium">
                                {{ $trans("microsoft_teams") }}
                              </span>
                              <br />
                              {{ $trans("microsoft_teams_description") }}
                            </p>
                          </v-card-text>
                          <v-card-text class="d-flex align-center">
                            <v-img
                              max-width="60"
                              class="mr-4"
                              :src="require('@/assets/skype-logo.png')"
                            />
                            <p>
                              <span class="font-weight-medium">
                                {{ $trans("microsoft_skype") }}
                              </span>
                              <br />
                              {{ $trans("microsoft_skype_description") }}
                            </p>
                          </v-card-text>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isOutlookCalendarEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isOutlookCalendarEnabled">{{
                      $trans("edit")
                    }}</span>
                    <span v-else>{{
                      $trans("outlook_calendar_connect_button_title")
                    }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="my-8" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_video") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disable="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleZoomClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <v-img
                          class="ml-4 rounded-circle"
                          max-width="80"
                          :src="require('@/assets/zoom-logo.jpg')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("zoom") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("zoom_connect_info") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isZoomEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isZoomEnabled">{{ $trans("edit") }}</span>
                    <span v-else>{{
                      $trans("zoom_connect_button_title")
                    }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disable="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleWhatsAppClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <v-img
                          class="ml-4"
                          max-width="80"
                          :src="require('@/assets/whatsapp-logo.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("whatsapp") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("whatsapp_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isWhatsAppEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isWhatsAppEnabled">{{ $trans("edit") }}</span>
                    <span v-else>{{
                      $trans("whatsapp_connect_button_title")
                    }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item
          v-if="loggedUserIsAdmin"
          :transition="false"
          :reverse-transition="false"
        >
          <v-container>
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_payments") }} &
                {{ $trans("integrations_category_accounting") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleStripeClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/stripe-logo.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("stripe") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("stripe_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isStripeEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isStripeEnabled">{{ $trans("edit") }}</span>
                    <span v-else>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleFakturowniaClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/fakturownia.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("plugins_fakturownia") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("fakturownia_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isFakturowniaEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isFakturowniaEnabled">{{
                      $trans("edit")
                    }}</span>
                    <span v-else>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>

            <v-divider class="my-8" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_automation") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleApiClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/api-integration.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("api") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("api_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon v-if="isApiEnabled" color="green">
                            $check
                          </v-icon>
                          <v-icon v-else color="light"> $plus</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span v-if="isApiEnabled">{{ $trans("edit") }}</span>
                    <span v-else>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleZapierClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/zapier-logo.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("zapier") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("zapier_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon color="light"> $open-in-new</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>

            <v-divider class="my-8" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_www") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleGtmClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/gtm-logo.svg')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("google_tag_manager") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("google_tag_manager_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon color="light"> $open-in-new</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleWidgetClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/logo-symbol.png')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("calendesk_widget") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("calendesk_widget_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon color="light"> $open-in-new</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>

            <v-divider class="my-8" />
            <v-row>
              <v-col class="text-h5">
                {{ $trans("integrations_category_analytics") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  outlined
                  :disabled="isLoading"
                  class="pointer calendesk-card fill-height"
                  min-height="200"
                  @click="handleGoogleAnalyticsClick"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="d-flex align-center fill-height" v-on="on">
                        <img
                          class="ml-4"
                          width="80"
                          :src="require('@/assets/google-analytics.svg')"
                        />
                        <div>
                          <v-card-title>
                            {{ $trans("google_analytics") }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $trans("google_analytics_description") }}
                          </v-card-subtitle>
                        </div>
                        <v-avatar tile size="40" class="mr-4">
                          <v-icon color="light"> $open-in-new</v-icon>
                        </v-avatar>
                      </div>
                    </template>
                    <span>{{ $trans("start_integration_label") }}</span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import api from "@/lib/calendesk-js-library/api/api";
import planActions from "@/calendesk/mixins/planActions";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "IntegrationTabs",
  mixins: [planActions],
  data() {
    return {
      isLoading: false,
      currentTab: 0,
    };
  },
  watch: {
    isLoading(val) {
      this.setIsSending(val);
    },
  },
  mounted() {
    this.loadZapierElement();
  },
  created() {
    document.documentElement.scrollTop = 0;

    if (this.loggedUserIsAdmin) {
      this.fetchApiKey().catch(() => {
        // Silent
      });
    }

    const { params } = this.$route;

    if (params) {
      const { integration } = params;

      if (integration) {
        if (integration === "google") {
          if (this.$route.query.code) {
            this.openDialog({
              type: dialogTypes.GOOGLE_CALENDAR,
              size: dialogSize.SMALL,
              title: this.$trans("google_calendar_auth_success_title"),
              ctaButtonAction: "handleSave",
              ctaButtonIcon: "$save",
              ctaButtonTitle: this.$trans("save"),
              data: {
                code: this.$route.query.code,
              },
            });
          } else {
            this.handleGoogleCalendarClick();
          }
        } else if (integration === "zoom") {
          if (this.$route.query.code) {
            this.openDialog({
              type: dialogTypes.ENABLE_ZOOM,
              size: dialogSize.SMALL,
              title: this.$trans("zoom"),
              data: {
                code: this.$route.query.code,
              },
            });
          } else {
            this.handleZoomClick();
          }
        } else if (integration === "microsoft") {
          this.openDialog({
            type: dialogTypes.OUTLOOK_CALENDAR,
            size: dialogSize.SMALL,
            title: this.$trans("outlook_calendar_auth_success_title"),
            ctaButtonAction: "handleSave",
            ctaButtonIcon: "$save",
            ctaButtonTitle: this.$trans("save"),
            data: {
              code: this.$route.query.code,
              state: this.$route.query.state,
            },
          });
        } else if (integration === "whatsapp") {
          this.handleWhatsAppClick();
        }

        if (this.loggedUserIsAdmin) {
          if (integration === "stripe") {
            this.currentTab = 1;
            this.handleStripeClick();
          } else if (integration === "fakturownia") {
            this.currentTab = 1;
            this.handleFakturowniaClick();
          } else if (integration === "api") {
            this.currentTab = 1;
            this.handleApiClick();
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getUserData: "auth/getUserData",
      fetchApiKey: "integrations/fetchApiKey",
    }),
    loadZapierElement() {
      const jsFile = document.getElementById("zapier-js");

      if (!jsFile) {
        // Load JS
        const script = document.createElement("script");
        script.type = "module";
        script.setAttribute("id", "zapier-js");
        script.src =
          "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
        document.head.appendChild(script);

        // Load CSS
        const stylesheet = document.createElement("link");
        stylesheet.rel = "stylesheet";
        stylesheet.href =
          "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
        document.head.appendChild(stylesheet);
      }
    },
    openApiDocumentation() {
      pushEvent("openApiDocumentation");

      window.open("https://api-doc.calendesk.com/", "_blank");
    },
    connectToGoogleCalendar() {
      if (this.canUseGoogleCalendar) {
        if (!this.isEmployee) {
          errorNotification("plugin_only_for_employees_info", null, false);
        } else {
          this.isLoading = true;

          pushEvent("connectToGoogleCalendar");

          api
            .createGoogleCalendarAuthUrl()
            .then(({ data }) => {
              if (data.url) {
                window.location.href = data.url;
              } else {
                this.isLoading = false;
              }
            })
            .catch((error) => {
              errorNotification(null, error);
              this.isLoading = false;
            });
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    connectToOutlookCalendar() {
      if (this.canUseOutlookCalendar) {
        if (!this.isEmployee) {
          errorNotification("plugin_only_for_employees_info", null, false);
        } else {
          this.isLoading = true;

          pushEvent("connectToOutlookCalendar");

          api
            .createOutlookCalendarAuthUrl()
            .then(({ data }) => {
              if (data.url) {
                window.location.href = data.url;
              } else {
                this.isLoading = false;
              }
            })
            .catch((error) => {
              errorNotification(null, error);
              this.isLoading = false;
            });
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleGoogleCalendarClick() {
      if (this.isGoogleCalendarEnabled) {
        this.openDialog({
          type: dialogTypes.GOOGLE_CALENDAR,
          size: dialogSize.SMALL,
          showCloseDialogFooter: true,
          title: this.$trans("google_calendar"),
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
        });
      } else {
        this.connectToGoogleCalendar();
      }
    },
    handleOutlookCalendarClick() {
      if (this.isOutlookCalendarEnabled) {
        this.openDialog({
          type: dialogTypes.OUTLOOK_CALENDAR,
          size: dialogSize.SMALL,
          showCloseDialogFooter: true,
          title: this.$trans("microsoft_outlook"),
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
        });
      } else {
        this.connectToOutlookCalendar();
      }
    },
    handleZoomClick(loader) {
      if (this.isZoomEnabled) {
        this.openDialog({
          type: dialogTypes.ZOOM,
          size: dialogSize.SMALL,
          title: this.$trans("zoom"),
          showCloseDialogFooter: true,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$close",
          ctaButtonColor: "error",
          ctaButtonTitle: this.$trans("disconnect_button_title"),
        });
      } else {
        this.connectToZoom(loader);
      }
    },
    handleWhatsAppClick() {
      if (this.canUseWhatsApp) {
        if (!this.isEmployee) {
          errorNotification("plugin_only_for_employees_info", null, false);
        } else {
          this.openDialog({
            type: dialogTypes.WHATSAPP,
            size: dialogSize.SMALL,
            showCloseDialogFooter: true,
            title: this.$trans("whatsapp"),
            ctaButtonAction: "handleSave",
            ctaButtonIcon: "$save",
            ctaButtonTitle: this.$trans("save"),
          });
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleStripeClick() {
      if (this.canUseStripe) {
        this.openDialog({
          type: dialogTypes.STRIPE,
          size: dialogSize.LARGE,
          title: this.$trans("stripe"),
          showCloseDialogFooter: true,
        });
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleFakturowniaClick() {
      if (this.canUseFakturownia) {
        this.openDialog({
          type: dialogTypes.FAKTUROWNIA,
          size: dialogSize.LARGE,
          title: this.$trans("plugins_fakturownia"),
          showCloseDialogFooter: true,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
        });
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleApiClick() {
      if (this.canUseApi) {
        this.openDialog({
          type: dialogTypes.API_INTEGRATION,
          size: dialogSize.SMALL,
          title: this.$trans("api"),
          showCloseDialogFooter: true,
        });
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleZapierClick() {
      if (this.canUseApi) {
        this.openDialog({
          type: dialogTypes.ZAPIER,
          size: dialogSize.LARGE,
          title: this.$trans("zapier"),
        });
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleGtmClick() {
      if (this.canUseGtm) {
        pushEvent("goToGoogleTagManager");

        if (getItem("locale", false) === "pl") {
          window.open(
            "https://calendesk.com/pl/help/article/google-tag-manager-dodaj-facebook-messengera/",
            "_blank"
          );
        } else {
          window.open(
            "https://calendesk.com/help/article/how-to-add-google-analytics-facebook-pixel-and-other-custom-scripts-to-calendesk/",
            "_blank"
          );
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleGoogleAnalyticsClick() {
      if (this.canUseGtm) {
        pushEvent("goToGoogleAnalytics");

        if (getItem("locale", false) === "pl") {
          window.open(
            "https://calendesk.com/pl/help/article/jak-podlaczyc-google-analytics-do-strony-zrobionej-w-calendesk/",
            "_blank"
          );
        } else {
          window.open(
            "https://calendesk.com/help/article/how-to-add-google-analytics-facebook-pixel-and-other-custom-scripts-to-calendesk/",
            "_blank"
          );
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    handleWidgetClick() {
      if (this.canUseGtm) {
        pushEvent("goToWidget");

        if (getItem("locale", false) === "pl") {
          window.open(
            "https://calendesk.com/pl/help/article/jak-dodac-system-rezerwacji-online-do-swojej-strony-internetowej/",
            "_blank"
          );
        } else {
          window.open(
            "https://calendesk.com/help/article/how-to-add-an-online-booking-system-to-your-website/",
            "_blank"
          );
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    connectToZoom() {
      if (this.canUseZoom) {
        if (!this.isEmployee) {
          errorNotification("plugin_only_for_employees_info", null, false);
        } else {
          pushEvent("connectToZoom");

          this.isLoading = true;
          api
            .createZoomAuthUrl()
            .then(({ data }) => {
              if (data.url) {
                window.location.href = data.url;
              } else {
                this.isLoading = false;
              }
            })
            .catch((error) => {
              errorNotification(null, error);
              this.isLoading = false;
            });
        }
      } else {
        this.pluginIsNotActivePopup();
      }
    },
    pluginIsNotActivePopup() {
      pushEvent("pluginIsNotActivePopup");

      if (this.loggedUserIsAdmin) {
        this.openFeatureNotAvailableDialog();
      } else {
        errorNotification("plugin_not_active_employee_info", null, false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
